import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import MetaTags from '@components/MetaTags';
import CookieManager from '@lib/CookieManager';
import { CAMPAIGN_CODE_COOKIE, REGISTER_VISIT } from '@lib/constants';
import RegisterLeftContent from '@ecosystems/register/RegisterLeftContent';
import { SignupForm } from '@ecosystems/register/RegisterV2Form';
import { SeeWhatsIncludedInPlay } from '@ecosystems/register/SeeWhatsIncludedInPlay';
import {
  RegistrationSourceKind,
  useAvailableTrialExtensionsQuery,
  useRegistrationPageQuery,
} from '@gql/generated';
import { TrialBanners } from '@ecosystems/register/TrialBanners';
import {
  extrapolateSourceFromReferrer,
  getSourceFromReferrer,
} from '@lib/helpers';
import { useApolloClient } from '@apollo/client';
import AnalyticsManager from '@lib/analytics/manager';
import { RegisterImage } from '@ecosystems/register/RegisterImage';
import { useTrackRegistration } from '@lib/hooks/useTrackRegistration';

// IMPORTANT
// TO KEEP ALL THE REGISTRATION PAGES LAYOUT/SPACING CONSISTENT
// WE WILL USE THE IMPORTED ELEMENTS FROM MAIN REGISTRATION
export const Wrapper = RegisterFormTemplate.Wrapper;

export const LeftBox = Styled.div`
  max-width: 500px;
  padding: 30px 20px;
  box-sizing: content-box;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    padding: 30px;
    margin-right: 10%;
    
    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right,rgba(223,245,255,0.95) 0%,rgba(223,245,255,0.95) 60%,rgba(223,245,255,0.1) 100%);
      z-index: -1;
    }
  }
`;

export const RightBox = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RightContainer = Styled(RegisterFormTemplate.RightContainer)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: stretch;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  /* needed on safari to keep this above left container during scroll on mobile */
  z-index: 10;
`;

export const Errors = Styled.ul`
  max-width: 500px;
  text-align: center;
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
`;

export const Error = Styled.li`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary['red-1']};
  margin: 0;
`;

export const Footer = Styled(RegisterFormTemplate.Footer)`
  width: 100%;
`;

/*
 the page supports two types of redirect params:
  - to: will be carried to the subscription page, after the payment we do the redirect
  - redirect_to|redirectTo: user will be redirected after the registration. (redirectTo is supported because of legacy usage)

  * redirect_to|redirectTo takes precedence over 'to'
*/

const PLAY_SOURCES = [
  RegistrationSourceKind['Video'],
  RegistrationSourceKind['Playlist'],
  RegistrationSourceKind['Challenge'],
  RegistrationSourceKind['Program'],
  RegistrationSourceKind['ContentHubBundle'],
  RegistrationSourceKind['Instructor'],
  RegistrationSourceKind['Blog'],
  null,
];
const Register = (props) => {
  const { data: registerPageImages } = useRegistrationPageQuery();
  const router = useRouter();
  const apolloClient = useApolloClient();

  const trackStep = useTrackRegistration();
  const [registerSource, setRegisterSource] = useState<{
    source: RegistrationSourceKind;
    source_id: string;
  }>(null);

  let trialCode =
    props.pageProps?.trialCode || router.query.trialCode || router.query.code;
  if (!trialCode && typeof window !== 'undefined') {
    trialCode = CookieManager.get({ key: CAMPAIGN_CODE_COOKIE });
  }

  const { data: subscriptionsData } = useAvailableTrialExtensionsQuery({
    variables: { code: trialCode },
    ssr: false,
  });

  const getSourceParams = async (_pathname) => {
    let _source = RegistrationSourceKind['Video'],
      _source_id = null;
    let pathname = _pathname;
    if (!pathname && document.referrer) {
      try {
        pathname = new URL(document.referrer).pathname;
      } catch (ex) {
        //
      }
    }

    if (pathname) {
      const urlSource = extrapolateSourceFromReferrer(decodeURI(pathname));

      if (Object.keys(urlSource?.params || {})?.length > 0) {
        const sourceData = await getSourceFromReferrer(apolloClient, urlSource);
        if (sourceData?.[0]) {
          (_source = sourceData?.[0].source),
            (_source_id = sourceData?.[0]?.source_id);
        }
      } else {
        _source = urlSource?.type;
      }
    }

    return {
      source: _source || RegistrationSourceKind['Video'],
      source_id: _source_id,
    };
  };

  useEffect(() => {
    if (router.query.hasOwnProperty('wellness')) {
      setRegisterSource({
        source: RegistrationSourceKind['Wellness'],
        source_id: null,
      });
      // if the user is coming from wellness page, we will redirect them to wellness page
    } else if (props.pageProps.registrationSource && !router.query?.to) {
      const url = new URL('https://domain' + router.asPath);
      url.searchParams.set('to', props.pageProps.registrationSource);
      if (
        String(props.pageProps.registrationSource).match(
          /\/(en|no|se|fi)\/wellness/
        )
      ) {
        url.searchParams.set('wellness', '');
      }
      (async () => {
        await router.replace(
          url.pathname + url.search,
          url.pathname + url.search,
          {
            shallow: true,
          }
        );
        getSourceParams(props.pageProps.registrationSource).then(
          ({ source, source_id }) => {
            setRegisterSource({ source, source_id });
          }
        );
      })();
    } else if (router.query.to) {
      getSourceParams(router.query?.to).then(({ source, source_id }) => {
        setRegisterSource({ source, source_id });
      });

      if (String(router.query?.to).match(/\/(en|no|se|fi)\/wellness/)) {
        const url = new URL('https://domain' + router.asPath);
        url.searchParams.set('to', props.pageProps.registrationSource);
        const url_ = url.pathname + url.search;
        router.replace(url_, url_, {
          shallow: true,
        });
      }
    } else {
      setRegisterSource({
        source: RegistrationSourceKind['Video'],
        source_id: null,
      });
    }
    AnalyticsManager().SendRegistrationStep1visited();
  }, []);

  useEffect(() => {
    if (
      [
        RegistrationSourceKind['Video'],
        RegistrationSourceKind['Playlist'],
        undefined,
      ].includes(registerSource?.source) &&
      !CookieManager.get({ key: REGISTER_VISIT })
    ) {
      const expiration = new Date();
      expiration.setHours(expiration.getHours() + 24);
      CookieManager.set({ key: REGISTER_VISIT, value: 1 }, expiration);
      trackStep('registration_visit');
    }
  }, [registerSource?.source]);

  let imageUrl = registerPageImages?.registrationPage?.playImageUrl;

  let title = <Trans id="register_v2.form.play_title" />,
    desc = <Trans id="register_v2.form.play_desc" />;

  if (registerSource?.source === RegistrationSourceKind['Live']) {
    imageUrl = registerPageImages?.registrationPage?.liveImageUrl;
    title = <Trans id="register_v2.form.live_title" />;
    desc = <Trans id="register_v2.form.live_desc" />;
  } else if (registerSource?.source === RegistrationSourceKind['Course']) {
    imageUrl = registerPageImages?.registrationPage?.courseImageUrl;
    title = <Trans id="register_v2.form.course_title" />;
    desc = <Trans id="register_v2.form.course_desc" />;
  } else if (
    [
      RegistrationSourceKind['B2hFar'],
      RegistrationSourceKind['B2hProgram'],
    ].includes(registerSource?.source)
  ) {
    imageUrl = registerPageImages?.registrationPage?.healthcareImageUrl;
  } else if (
    registerSource?.source === RegistrationSourceKind['ContentHubBundle']
  ) {
    imageUrl = registerPageImages?.registrationPage?.contentHubImageUrl;
    title = <Trans id="register_v2.form.contenthub_title" />;
    desc = <Trans id="register_v2.form.contenthub_desc" />;
  } else if (registerSource?.source === RegistrationSourceKind['Wellness']) {
    title = <Trans id="register_v2.form.wellness_title" />;
    desc = <Trans id="register_v2.form.wellness_desc" />;
  }

  return (
    <>
      <MetaTags
        title="Yoga och träning online för hållbar hälsa"
        description="Yoga & träna på nätet med några av Sveriges främsta instruktörer. Yogavideos online vart du vill, när du vill."
      />
      <Wrapper>
        <RightContainer>
          <TrialBanners
            // courseCookies={courseCookies}
            campaignCode={
              subscriptionsData?.availableTrialExtensions?.code &&
              subscriptionsData?.availableTrialExtensions?.isCodeValid
                ? subscriptionsData?.availableTrialExtensions?.code
                : null
            }
          />
          <RightBox>
            {/* {courseCookies?.['courseTitle'] &&
            !courseCookies['courseAccessCode'] ? (
              <CourseCard
                name={courseCookies['courseTitle']}
                image={courseCookies['courseImg']}
                price={courseCookies['courseAmount']}
                original_price={courseCookies['courseOriginalAmount']}
              />
            ) : null} */}
            <div className="flex-1 size-full flex flex-col pt-8">
              <div className="max-w-2xl w-full mx-auto px-4">
                <h1 className="text-2xl md:text-3xl font-medium text-balance mt-0">
                  {title}
                </h1>
                <RegisterImage src={imageUrl} />
                {PLAY_SOURCES.includes(registerSource?.source) ? (
                  <SeeWhatsIncludedInPlay />
                ) : null}
                <p
                  id="form-desc"
                  className="max-w-2xl w-full text-base text-dark font-medium pt-4 my-0 mx-auto"
                >
                  {desc}
                </p>
              </div>
              <SignupForm
                registerSource={registerSource}
                trialCode={props.pageProps.trialCode}
              />
            </div>
          </RightBox>
          {/* <Footer /> */}
        </RightContainer>
        <RegisterLeftContent />
      </Wrapper>
    </>
  );
};

export const getServerSideProps = (ctx) => {
  const { currentUser } = ctx.req;
  const { lang, redirect_to, redirectTo, to } = ctx.query;

  if (currentUser?.id) {
    let url = `/${lang}/videos/genres/all`;
    if (redirectTo || redirect_to || to) {
      url = redirectTo || redirect_to || to;
    }
    return {
      redirect: { destination: url, permanent: false },
      props: {},
    };
  }

  let registrationSource = null;

  if (!to && ctx?.req?.headers.referer) {
    try {
      const referrerUrl = new URL(ctx.req?.headers?.referer);
      if (
        !String(referrerUrl.pathname).match(/\/(se|fi|no|en)\/register/) &&
        referrerUrl.host.match(
          /^(devenv\.yogobe\.com|yogobe\.com|[a-z0-9-]*-yogobe\.vercel\.app|localhost)/
        )
      ) {
        registrationSource = referrerUrl.pathname + referrerUrl.search;
      }
    } catch (ex) {
      // referer is probably an invalid url
    }
  }

  // we load this from cookies on server
  // to provide it for the page as pageProps during ssr where cookies are not available...
  const trialCode =
    CookieManager.get({
      key: CAMPAIGN_CODE_COOKIE,
      req: ctx.req,
    }) || '';

  return {
    props: { trialCode, registrationSource },
  };
};

Register.getPageConfig = () => {
  return {
    layout: 'none',
  };
};

export default Register;
